import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../store";

import { requestSymbol } from "./volatilityAnalysisActions";

// export interface AuthState {
//   authentication: object;
// }

// const initialState: AuthState = {

export const enum Status {
  LOADING = "LOADING",
  IDLE = "IDLE",
}

export interface VolatilityAnalysisData {
  [index: string]: {
    [index: string]: {
      open: number;
      high: number;
      low: number;
      close: number;
      futuresExpirationDate: string;
      options: {
        [index: string]: {
          meta: { expirationDate: string };
          ivol: {
            Calls: { [index: string]: number };
            Puts: { [index: string]: number };
          };
        };
      };
    };
  };
}

const initialState = {
  data: {},
  status: Status.IDLE,
};

export const volatilityAnalysisSlice = createSlice({
  name: "volatilityAnalysis",
  initialState,
  reducers: {
    reset: (state) => initialState,
    // setVolatilityAnalysisData: (state, action) => {
    //   console.debug("state:");
    //   console.debug(state);
    //   console.debug("action:");
    //   console.debug(action);
    //   // state.profile.createdAt = action.payload.created_at;
    //   state.data = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestSymbol.pending, (state, action) => {
        state.status = Status.LOADING;
      })
      .addCase(requestSymbol.fulfilled, (state, action) => {
        console.debug("state:");
        console.debug(state);
        console.debug("action:");
        console.debug(action);
        state.data = action.payload;
        state.status = Status.IDLE;
      })
      .addCase(requestSymbol.rejected, (state, action) => {
        state.status = Status.IDLE;
        console.debug("requestSymbol.rejected action:");
        console.debug(action);
        state.status = Status.IDLE;
      });
  },
});

const { actions, reducer } = volatilityAnalysisSlice;

// actions automatically generated by redux
export const { reset } = actions;

export const selectVolatilityAnalysis = (state: RootState) =>
  state.volatilityAnalysis;

export default reducer;
