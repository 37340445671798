import BSTextInput from "../forms/BSTextInput";
import logoUrl from "../../assets/csc_logo_transparent.png";
import styles from "./Login.module.css";
import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import {
  selectAuth,
  reset as resetAuth,
  setLoginMessage,
} from "../auth/authSlice";
import { login } from "../auth/authActions";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { useNavigate, Link } from "react-router-dom";
import validateEmail from "../validators/emailValidator";
import { useState, useEffect, ChangeEvent, FormEvent, useRef } from "react";

export function Login(props: {
  resetPasswordUrl: string;
  registerUrl: string;
  exitUrl: string;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { email, isLoggedIn, loginMessage, authentication } =
    useAppSelector(selectAuth);

  const emailRef = useRef<HTMLInputElement>(null);

  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const isMounted = useMounted();

  const resetState = () => {
    setFormEmail("");
    setFormPassword("");
    setEmailError("");
    setPasswordError("");
    setEmailValid(false);
    setPasswordValid(false);
    setEmailTouched(false);
    setPasswordTouched(false);
    setSubmitDisabled(false);
  };

  // focus email when component mounts
  useEffect(() => {
    // set focus to email address form input
    // console.debug("focusing email...");
    emailRef.current?.focus();
  }, []); // <-- no dependecies so this only runs once after mount

  // Reset on navigate
  useEffect(() => {
    // dispatch(resetAuth());
    resetState();
  }, [navigate, dispatch, emailRef]);

  // redirect to loading screen
  useEffect(() => {
    if (isLoggedIn && email) {
      // If success or email in state then redirect to exit url
      navigate(props.exitUrl);
    }
  }, [email, isLoggedIn, navigate]);

  // handle authentication state
  useEffect(() => {
    setSubmitDisabled(authentication.isLoading);
  }, [authentication]);

  // validate form
  useEffect(() => {
    setSubmitDisabled(!(emailValid && passwordValid));
  }, [emailValid, passwordValid]);

  // Validate email
  useEffect(() => {
    if (isMounted) {
      const email_validation = validateEmail(formEmail);
      if (email_validation.valid) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
      setEmailError(email_validation.message);
    }
    // we are not adding isMounted to the dependency array as it would result in error message being shown early
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formEmail]);

  // Validate password
  useEffect(() => {
    if (isMounted) {
      if (formPassword.length === 0) {
        setPasswordError("Please enter a password");
        setPasswordValid(false);
      } else {
        setPasswordValid(true);
        setPasswordError("");
      }
    }
    // we are not adding isMounted to the dependency array as it would result in error message being shown early
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPassword]);

  const createOnChange = (_fn: (s: string) => void) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Update credentials in state
      _fn(e.target.value);
    };
    return onChange;
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // clear any loginMessage that had been displayed from the global state
    dispatch(setLoginMessage(""));

    const email_validation = validateEmail(formEmail);
    if (email_validation.valid) {
      dispatch(login({ email: formEmail, password: formPassword }));
    } else {
      setEmailError(email_validation.message);
    }
  };

  return (
    <div
      className={"card mx-auto auth-card w-100 " + styles.loginContainer}
      // style={{ minWidth: "300px" }}
    >
      <div className="card-body">
        <div className="w-100 text-center mb-2">
          <img
            src={logoUrl}
            alt="AG Logo"
            className={"mt-2 mb-4 mx-auto " + styles.companyLogo}
          />
        </div>
        <div className="text-center">
          <h5 className="card-title mb-4 px-3">
            Welcome to the
            <br />
            Trader Portal
          </h5>
        </div>
        {loginMessage && loginMessage != "" && (
          <div className="text-primary text-center mt-4">
            {/* Error message */}
            <p>{loginMessage}</p>
          </div>
        )}

        <div className="mt-2 text-left">
          <form onSubmit={onSubmit}>
            <BSTextInput
              ref={emailRef}
              label={"Email Address"}
              type={"email"}
              onChange={createOnChange(setFormEmail)}
              name={"email"}
              value={formEmail}
              touched={emailTouched}
              message={emailError}
              required={false}
              placeholder={"someone@example.com"}
              id={"email"}
              onBlur={() => setEmailTouched(true)}
            />

            <BSTextInput
              label={"Password"}
              type={"password"}
              onChange={createOnChange(setFormPassword)}
              name={"password"}
              value={formPassword}
              touched={passwordTouched}
              message={passwordError}
              required={false}
              id={"password"}
              placeholder={"password"}
              autoComplete="off"
              onBlur={() => setPasswordTouched(true)}
            />

            <div className="text-center">
              <button
                className="btn btn-primary w-75 mt-4"
                disabled={submitDisabled || authentication.isLoading}
                type="submit"
              >
                {authentication.isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Login</>
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="mt-4 w-100 text-center">
          <small>
            New user? Click{" "}
            <Link style={{ textDecoration: "none" }} to={props.registerUrl}>
              here
            </Link>{" "}
            to register.
          </small>
        </div>

        <div className="mt-4 w-100 text-center">
          <small>
            <Link
              style={{ textDecoration: "none" }}
              to={props.resetPasswordUrl}
            >
              Forgot password?
            </Link>{" "}
          </small>
        </div>

        {authentication.message && authentication.message != "" && (
          <div className="text-danger text-center mt-4">
            {/* Error message */}
            <p>{authentication.message}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
