import Plot from "react-plotly.js";
// import BSTextInput from "../forms/BSTextInput";
import styles from "./VolatilityAnalysis.module.css";
import useMounted from "../../hooks/useMounted";
// import { partial } from "lodash-es";
import { selectAuth } from "../auth/authSlice";
import {
  selectVolatilityAnalysis,
  // setVolatilityAnalysisData,
  VolatilityAnalysisData,
  Status,
} from "./volatilityAnalysisSlice";
import { useAppSelector, useAppDispatch } from "../../hooks/redux";
import { useNavigate, Link } from "react-router-dom";
import { useState, useEffect, ChangeEvent, FormEvent, useRef } from "react";
import { requestSymbol } from "./volatilityAnalysisActions";
import { Height } from "@mui/icons-material";

// class FlatPrice {
//   #dates: Date[];
//   #values: number[];

//   constructor(dates: string[] | Date[], values: number[]) {
//     this.dates = dates;
//     this.values = values;
//   }
// }

export function VolatilityAnalysis(props: {}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // "inputs" - what we watch that determines whether we update
  const { email, isLoggedIn, loginMessage } = useAppSelector(selectAuth);
  const { status } = useAppSelector(selectVolatilityAnalysis);
  const volatilityAnalysisData: VolatilityAnalysisData = useAppSelector(
    (state) => state.volatilityAnalysis.data,
  );

  const symbolRef = useRef<HTMLInputElement>(null);

  const [currentDate, setCurrentDate] = useState("");
  const [formSymbol, setFormSymbol] = useState("");
  const [symbolTouched, setSymbolTouched] = useState(false);
  const [symbolError, setSymbolError] = useState("");
  const [formSlider, setFormSlider] = useState<number>(0);
  const [generalError, setGeneralError] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(1);
  const [timerIntervalId, setTimerIntervalId] = useState<
    string | number | NodeJS.Timeout | undefined
  >(undefined);
  const [animationStep, setAnimationStep] = useState(0);
  const [nextAnimationStep, setNextAnimationStep] = useState(0);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [selectedFutures, setSelectedFutures] = useState<string[]>([]);

  const isMounted = useMounted();

  const resetState = () => {
    console.debug("resetState...");

    setCurrentDate("");
    setFormSymbol("");
    setSymbolTouched(false);
    setSymbolError("");
    setFormSlider(0);
    setGeneralError("");
    setSubmitDisabled(false);
    setIsLoading(false);
    setIsAnimating(false);
    setAnimationSpeed(1);
    setTimerIntervalId(undefined);
    setAnimationStep(0);
    setNextAnimationStep(0);
    setSelectedOptions([]);
    setSelectedFutures([]);
  };

  // Reset on navigate
  useEffect(() => {
    console.debug("resetting state...");
    resetState();
  }, [navigate, dispatch]);

  // focus symbol field when component mounts
  useEffect(() => {
    console.debug("focusing symbol...");
    symbolRef.current?.focus();
  }, []); // <-- no dependecies so this only runs once after mount

  useEffect(() => {
    console.debug(`updated volatilityAnalysisData:`);
    console.debug(volatilityAnalysisData);
    setCurrentDate(Object.keys(volatilityAnalysisData)[0]);

    // console.debug("useEffect 1");
    if (volatilityAnalysisData && volatilityAnalysisData[currentDate]) {
      const fut_keys = Object.keys(volatilityAnalysisData[currentDate]);

      if (fut_keys.length > 0) {
        setSelectedFutures(fut_keys.slice(0, 1));
      }
    }

    // console.debug("useEffect 2");
    // TODO: update this effect hook to handle all futures selections
    // (make need a sort-selection handler that depends on metadata)
    if (
      volatilityAnalysisData &&
      volatilityAnalysisData[currentDate] &&
      volatilityAnalysisData[currentDate]["ZCN24"] &&
      volatilityAnalysisData[currentDate]["ZCN24"]["options"]
    ) {
      const keys = Object.keys(
        volatilityAnalysisData[currentDate]["ZCN24"]["options"],
      );

      // Automatically select the first 3 items if they exist
      if (keys.length > 0) {
        setSelectedOptions(keys.slice(0, 3));
      }
    }
  }, [volatilityAnalysisData, dispatch]);

  useEffect(() => {
    console.debug("updating status...");
    console.debug(`status: ${status}`);
    switch (status) {
      case Status.IDLE:
        setSubmitDisabled(false);
        setIsLoading(false);
        break;
      case Status.LOADING:
        setSubmitDisabled(true);
        setIsLoading(true);
        break;
      default:
        setSubmitDisabled(false);
        setIsLoading(false);
    }
  }, [status, dispatch]);

  useEffect(() => {
    // clear previous timer, if it exists
    try {
      console.debug("destroying timer...");
      clearInterval(timerIntervalId);
    } catch {
      console.debug("Unable to destroy timer");
    }
    if (isAnimating) {
      if (animationStep >= nextAnimationStep) {
        setTimerIntervalId(setInterval(animate, 50 * animationSpeed));
      }
      setNextAnimationStep(animationStep + 1);
    }
  }, [animationSpeed, isAnimating, animationStep]);

  const animate = () => {
    console.debug("animating...");
    console.debug(`${Object.keys(volatilityAnalysisData).length - 1}`);
    console.debug(`${formSlider}`);
    if (formSlider < Object.keys(volatilityAnalysisData).length - 1) {
      setFormSlider(formSlider + 1);
      setCurrentDate(Object.keys(volatilityAnalysisData)[formSlider + 1]);
      setAnimationStep(animationStep + 1);
    }
  };
  // disable submit button unless symbol is valid(?)
  // useEffect(() => {
  // setSubmitDisabled(!(emailValid && passwordValid));
  // }, [emailValid, passwordValid]);

  const handleFutCheckboxChange = (key: string) => {
    setSelectedFutures(
      (prevSelectedItems) =>
        prevSelectedItems.includes(key)
          ? prevSelectedItems.filter((item) => item !== key) // Deselect
          : [...prevSelectedItems, key], // Select
    );
  };

  const handleCheckboxChange = (key: string) => {
    setSelectedOptions(
      (prevSelectedItems) =>
        prevSelectedItems.includes(key)
          ? prevSelectedItems.filter((item) => item !== key) // Deselect
          : [...prevSelectedItems, key], // Select
    );
  };

  const createOnChange = (_fn: (s: string) => void) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      // Update credentials in state
      _fn(e.target.value);
    };
    return onChange;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    // setSubmitDisabled(true);
    // setIsLoading(true);
    setGeneralError("");
    /*
    {
      '2024-05-20':
        'ZCN24': {
          'open':
          'high':
          'low':
          'close':
          'contractDate':
          'ivol': {
            'ZCM24': {
              'put': {
                '320': 1.40...,
                ...
              }
              'call': {
                '320': 1.4042...,
                ...
              }
            }
          }

        }
    }
    */

    dispatch(requestSymbol(formSymbol));

    // setGeneralError(dataResult.user_message ? dataResult.user_message : "");

    // } else {
    // setErrorMessages("Please check your credentials!")
    // }
  };

  const updateSlider = (e: ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    setFormSlider(Number(e.target.value));
    setCurrentDate(Object.keys(volatilityAnalysisData)[Number(e.target.value)]);
  };

  const toggleAnimation = (e: ChangeEvent<HTMLInputElement>) => {
    // e.preventDefault();
    if (isAnimating) {
      // setAnimationStep(0);
      // setNextAnimationStep(0);
    }
    setIsAnimating(!isAnimating);
  };

  const symbolSort = (a: string, b: string): number => {
    if (a.slice(-2) < b.slice(-2)) {
      return -1;
    }
    if (a.slice(-2) > b.slice(-2)) {
      return 1;
    }
    if (a.slice(-2) === b.slice(-2)) {
      if (a.slice(-3) < b.slice(-3)) {
        return -1;
      }
      if (a.slice(-3) > b.slice(-3)) {
        return 1;
      }
      if (a.slice(-3) === b.slice(-3)) {
        return 0;
      }
    }
    return 999; // error? typescript will complain if we don't return a number
  };

  const maxIvol = (): number => {
    const vad = volatilityAnalysisData;

    // Gather all implied volatilities across dates, futures, and options
    const allVols = Object.keys(vad).flatMap((date) =>
      selectedFutures.flatMap((future) =>
        Object.keys(vad[date][future]["options"])
          .filter((k) => selectedOptions.includes(k))
          .flatMap((option) => {
            const calls = Object.values(
              vad[date][future]["options"][option]?.["ivol"]["Calls"] || {},
            );
            const puts = Object.values(
              vad[date][future]["options"][option]?.["ivol"]["Puts"] || {},
            );
            return [...calls, ...puts]; // Combine calls and puts
          }),
      ),
    );

    console.debug(allVols); // Log all implied volatilities
    console.debug(Math.max(...allVols));

    // Return the maximum or 0 if no volatilities were found
    return allVols.length > 0 ? Math.max(...allVols) : 0;
  };
  return (
    <div>
      <div className="w-100 bg-light">
        <div className="d-flex flex-column bg-light w-50 mx-2 my-2">
          <div className="my-2">Symbol:</div>
          <div className="d-flex flex-row">
            <div>
              <input
                type="text"
                className="form-control"
                id="inputSymbol"
                ref={symbolRef}
                value={formSymbol}
                placeholder="/ES"
                onChange={createOnChange(setFormSymbol)}
                onBlur={() => setSymbolTouched(true)}
              />
              <p className="text-danger validation-error">
                {symbolTouched && symbolError}
              </p>
            </div>
            <div>
              <button
                className="btn btn-primary mb-2 mx-2"
                disabled={submitDisabled || isLoading}
                type="button"
                onClick={onSubmit}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </>
                ) : (
                  <>Submit</>
                )}
              </button>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div>
              {generalError && generalError !== "" && (
                <div className="text-danger text-center mt-4">
                  {/* Error message */}
                  <p>{generalError}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 bg-light">
        <div>Current Date: {currentDate}</div>
        <div className="d-flex flex-row">
          <label htmlFor="dateRange" className="form-label">
            Select Date:
          </label>
          <div className="bg-dark">
            <input
              type="range"
              className="form-range"
              id="dateRange"
              name="dateRange"
              value={formSlider}
              min="0"
              max={
                Object.keys(volatilityAnalysisData).length
                  ? (Object.keys(volatilityAnalysisData).length - 1).toString()
                  : "3"
              }
              // max="20"
              step="1"
              onChange={updateSlider}
            />
          </div>
          <div>Slider value: {formSlider}</div>
        </div>
        {/*        <div>
          <button className="btn btn-primary" onClick={toggleAnimation}>
            {isAnimating ? "Pause" : "Play"}
          </button>
        </div>*/}
        {volatilityAnalysisData && currentDate && (
          <>
            <Plot // BUG: Plot shows an error due to overload mismatch
              data={
                [
                  ...selectedFutures.flatMap((future) => [
                    {
                      x: Object.keys(volatilityAnalysisData),

                      y: Object.keys(volatilityAnalysisData).map(
                        (k) => volatilityAnalysisData[k][future]["close"],
                      ),

                      type: "scattergl",
                      mode: "lines+markers",
                      marker: { color: "red" },
                      name: future + " - close",
                    },
                    {
                      x: Object.keys(volatilityAnalysisData),

                      open: Object.keys(volatilityAnalysisData).map(
                        (k) => volatilityAnalysisData[k][future]["open"],
                      ),
                      high: Object.keys(volatilityAnalysisData).map(
                        (k) => volatilityAnalysisData[k][future]["high"],
                      ),
                      low: Object.keys(volatilityAnalysisData).map(
                        (k) => volatilityAnalysisData[k][future]["low"],
                      ),
                      close: Object.keys(volatilityAnalysisData).map(
                        (k) => volatilityAnalysisData[k][future]["close"],
                      ),

                      type: "candlestick",
                      name: future,
                    },

                    // { type: "bar", x: [1, 2, 3], y: [2, 5, 3] },
                  ]),
                  {
                    type: "scattergl",
                    // xref: "x",
                    // yref: "paper",
                    x: [currentDate, currentDate],
                    y: [
                      Math.min(
                        ...selectedFutures.flatMap((future) =>
                          Object.keys(volatilityAnalysisData).map(
                            (k) => volatilityAnalysisData[k][future]["low"],
                          ),
                        ),
                      ),
                      Math.max(
                        ...selectedFutures.flatMap((future) =>
                          Object.keys(volatilityAnalysisData).map(
                            (k) => volatilityAnalysisData[k][future]["high"],
                          ),
                        ),
                      ),
                    ],
                    // x0: currentDate,
                    // y0: 0,
                    // x1: currentDate,
                    // y1: 1,
                    line: { color: "blue", width: 4.0, dash: "solid" },
                    mode: "lines",
                    marker: { color: "blue" },
                    name: "Current Date",
                  },
                ] as Object[]
              }
              // layout={{ width: 320, height: 240, title: "A Fancy Plot" }}
              layout={{
                title: "Flat Price",
                xaxis: { fixedrange: true, rangeslider: { visible: false } },
              }}
            />

            <Plot
              data={[
                {
                  x: Object.keys(volatilityAnalysisData[currentDate]).sort(
                    symbolSort,
                  ),
                  y: Object.keys(volatilityAnalysisData[currentDate])
                    .sort(symbolSort)
                    .map(
                      (k) => volatilityAnalysisData[currentDate][k]["close"],
                    ),
                  type: "scattergl",
                  mode: "lines+markers",
                  marker: { color: "blue" },
                },

                // { type: "bar", x: [1, 2, 3], y: [2, 5, 3] },
              ]}
              // layout={{ width: 320, height: 240, title: "A Fancy Plot" }}
              layout={{
                title: "Forward Curve",
                yaxis: {
                  range: [
                    -5 +
                      Math.min(
                        ...Object.keys(volatilityAnalysisData).flatMap((date) =>
                          Object.keys(volatilityAnalysisData[date])
                            .map(
                              (future) =>
                                volatilityAnalysisData[date][future].close,
                            )
                            .filter(
                              (close) =>
                                close !== null && typeof close === "number",
                            ),
                        ),
                      ),
                    5 +
                      Math.max(
                        ...Object.keys(volatilityAnalysisData).flatMap((date) =>
                          Object.keys(volatilityAnalysisData[date]).map(
                            (future) =>
                              volatilityAnalysisData[date][future].close,
                          ),
                        ),
                      ),
                  ],
                },
              }}
            />
            <Plot
              data={
                selectedFutures.flatMap((future) => {
                  const optionsData =
                    volatilityAnalysisData[currentDate][future]["options"];
                  const futureClose =
                    volatilityAnalysisData[currentDate][future]["close"];
                  return Object.keys(optionsData)
                    .filter((k) => selectedOptions.includes(k))
                    .map((option) => {
                      const ivol = optionsData[option]["ivol"];
                      const ivolPuts = ivol["Puts"];
                      const ivolCalls = ivol["Calls"];

                      // Prepare arrays for keys and values
                      const keys = [];
                      const values: number[] = [];

                      // Filter and add puts (keys less than futureClose)
                      for (const key in ivolPuts) {
                        if (Number(key) < futureClose) {
                          keys.push(Number(key));
                          values.push(ivolPuts[key]);
                        }
                      }

                      // Filter and add calls (keys greater than futureClose)
                      for (const key in ivolCalls) {
                        if (Number(key) > futureClose) {
                          keys.push(Number(key));
                          values.push(ivolCalls[key]);
                        }
                      }

                      // Combine and sort the keys and corresponding values
                      const combined = keys.map((key, index) => ({
                        key,
                        value: values[index],
                      }));
                      combined.sort((a, b) => a.key - b.key); // Sort by key

                      // Extract sorted keys and values back into separate arrays
                      const sortedKeys = combined.map((entry) => entry.key);
                      const sortedValues = combined.map((entry) => entry.value);

                      // Return the structured data for the option
                      return {
                        x: sortedKeys,
                        y: sortedValues,
                        type: "scattergl",
                        mode: "lines+markers",
                        name: option,
                      };
                    });
                }) as Object[]
              }
              // layout={{ width: 320, height: 240, title: "A Fancy Plot" }}
              layout={{
                title: "IVol",
                yaxis: {
                  range: [0, maxIvol()],
                },
              }}
            />

            <div
              className="card"
              style={{
                width: "300px",
                height: "450px",
                marginLeft: "15px",
                display: "inline-block",
                verticalAlign: "top",
                overflowY: "auto",
                scrollbarWidth: "thin",
              }}
            >
              <div
                className="card-body p-2 d-flex flex-column"
                style={{ overflowY: "auto" }} //todo, fix scrollbar on whole div, requires subdiv
              >
                <h6 className="text-center py-2">Available Options</h6>
                <hr className="my-0" />

                <div className="flex-grow-1" style={{ overflowY: "auto" }}>
                  <div className="px-3">
                    {(() => {
                      const allOptions = selectedFutures.flatMap((future) => {
                        const options =
                          volatilityAnalysisData[currentDate][future]
                            ?.options || {};

                        return Object.entries(options).map(([key, value]) => ({
                          key,
                          expirationDate: new Date(value.meta.expirationDate),
                          future,
                        }));
                      });

                      const sortedOptions = allOptions.sort(
                        (
                          { expirationDate: dateA },
                          { expirationDate: dateB },
                        ) => {
                          if (dateA === null && dateB === null) return 0;
                          if (dateA === null) return 1;
                          if (dateB === null) return -1;

                          return dateA.getTime() - dateB.getTime();
                        },
                      );

                      return sortedOptions.map(
                        ({ key, expirationDate, future }) => (
                          <div key={future + "-" + key}>
                            <label className="form-check-label">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={key}
                                checked={selectedOptions.includes(key)}
                                onChange={() => handleCheckboxChange(key)}
                              />
                              {" " +
                                key +
                                " - " +
                                expirationDate.toLocaleDateString() +
                                " (" +
                                future +
                                ")"}
                            </label>
                            <br />
                          </div>
                        ),
                      );
                    })()}
                  </div>
                </div>
              </div>
            </div>

            {/* Futures Selection list */}
            <div
              className="card"
              style={{
                width: "275px",
                height: "450px",
                marginLeft: "15px",
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              <div
                className="card-body p-2 d-flex flex-column"
                style={{ overflowY: "auto" }}
              >
                <h6 className="text-center py-2">Available Futures</h6>
                <hr className="my-0" />

                <div className="flex-grow-1" style={{ overflowY: "auto" }}>
                  <div className="px-3">
                    {Object.keys(volatilityAnalysisData[currentDate])
                      .sort((a, b) => {
                        const vol = volatilityAnalysisData[currentDate];
                        const dateA = vol[a].futuresExpirationDate
                          ? new Date(vol[a].futuresExpirationDate)
                          : null;
                        const dateB = vol[b].futuresExpirationDate
                          ? new Date(vol[b].futuresExpirationDate)
                          : null;

                        // Handle sorting logic with nulls
                        if (dateA === null && dateB === null) return 0;
                        if (dateA === null) return 1;
                        if (dateB === null) return -1;

                        return dateA.getTime() - dateB.getTime();
                      })
                      .map((key) => (
                        <div key={key}>
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={key}
                              checked={selectedFutures.includes(key)}
                              onChange={() => handleFutCheckboxChange(key)}
                            />
                            {" " +
                              key +
                              " ( " +
                              Object.keys(
                                volatilityAnalysisData[currentDate][key][
                                  "options"
                                ],
                              ).length +
                              " ) - " +
                              volatilityAnalysisData[currentDate][key][
                                "futuresExpirationDate"
                              ]}
                          </label>
                          <br />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default VolatilityAnalysis;
